import Svg from "./svg";

function Talk_Ar() {
  return (
    <div className="Ar  max-w-screen h-screen overflow-hidden flex items-center justify-center mt-8">
      <Svg />
      <div className="text-white text-2xl md:text-3xl text-center pb-32 px-2">
        كل ما تريد لين مبيعاتك تزيد
      </div>
    </div>
  );
}

export default Talk_Ar;
