import React from "react";
import phone from "./phone.png";
import mail from "./mail.png";
import Social from "./socialmedialinks";
import logo5 from "./logo5.png";

function Footer_Ar() {
  return (
    <div className="Ar w-screen  bottom-0 mx-auto flex flex-col items-center justify-center mt-16 pt-8 border-t-[1px] border-[#f0f0f0]">
      <img src={logo5} className="w-28 mb-4" alt="Logo" />

      <div className="text-white text-center mb-8 flex flex-col items-center">
        <div className="flex items-center mb-2">
          <img src={phone} className="w-6" alt="Phone Icon" />
          <div className="text-white ml-1">+968 91828471</div>
        </div>
        <div className="flex items-center">
          <img src={mail} className="w-6" alt="Mail Icon" />
          <div className="text-white ml-2">marketing@imteiaz.com</div>
        </div>
      </div>

      <div className="flex items-center justify-center gap-4">
        <Social />
      </div>

      <div className="text-white text-center text-sm mt-8">
        .2024 ©جميع الحقوق محفوظة إمتياز
      </div>

      <div className="text-white text-center text-sm mt-2 mb-4">
        رواد التقنية للتجارة والاستثمار - سجل رقم 1484464
      </div>
    </div>
  );
}

export default Footer_Ar;
