import { Link as ScrollLink } from "react-scroll";
import logo5 from "./logo5.png";
import { useState } from "react";
import MobileNavbar from "./MobileNavbar";
import { Link } from "react-router-dom";

function Hero() {
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  return (
    <>
      <div className="md:hidden flex justify-between items-center px-4 py-2">
        <div>
          <img className="w-32" alt="Logo" src={logo5} />
        </div>

        <button
          className="text-white focus:outline-none"
          onClick={() => setMobileNavOpen(!isMobileNavOpen)}
        >
          <svg
            className="w-10 h-10"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 18 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16m-7 6h7"
            ></path>
          </svg>
        </button>
      </div>

      <MobileNavbar
        isOpen={isMobileNavOpen}
        toggleNavbar={() => setMobileNavOpen(!isMobileNavOpen)}
      />

      <section className="text-white md:w-screen h-screen">
        <div class="grid md:grid-cols-6  md:gap-4 items-center justify-center md:mt-6">
          <div class=" col-span-3 md:ml-24 md:flex hidden">
            <img className="md:w-32 bg-opacity-50" alt="Logo" src={logo5} />
          </div>
          <div class="hidden col-span-3 col-start-7 md:mr-24  md:flex gap-4 md:text-xl">
            <div className="hover:text-[#0881a3] cursor-pointer ">
              <ScrollLink
                to="services"
                smooth={true}
                duration={800}
                offset={-35}
              >
                Services
              </ScrollLink>
            </div>
            <div className="hover:text-[#0881a3] cursor-pointer ">
              <ScrollLink
                to="pricing"
                smooth={true}
                duration={800}
                offset={-35}
              >
                Pricing
              </ScrollLink>
            </div>
            <div className="hover:text-[#0881a3] cursor-pointer">
              <ScrollLink to="talk" smooth={true} duration={800} offset={-35}>
                Culture
              </ScrollLink>
            </div>
            <div className="hover:text-[#0881a3] cursor-pointer">
              <ScrollLink
                to="contact"
                smooth={true}
                duration={800}
                offset={-35}
              >
                Connect
              </ScrollLink>
            </div>
            <div className="hover:text-[#0881a3] cursor-pointer">
              <ScrollLink to="about" smooth={true} duration={800} offset={-35}>
                About
              </ScrollLink>
            </div>
            <div className="hover:text-[#0881a3] cursor-pointer border-l-2 pl-2 border-[#0881a3]">
              <Link to="/Ar"> Ar</Link>
            </div>
          </div>
        </div>

        <div className="flex items-center justify-center text-center absolute w-screen h-4/5">
          <div className="text-2xl md:text-6xl font-bold mx-auto">
            Do You Have A Great Product ? <br />
            <br />
            Let's Promote It !<br />
          </div>
        </div>
        <div className="flex items-center justify-center text-center absolute bottom-20 w-screen ">
          <button className="text-white bg-[#339fbeff] border-[#339fbeff] border-2 p-1 rounded text-lg font-serif">
            Get In Touch
          </button>
        </div>

        <video
          className="absolute inset-0 w-screen h-screen object-cover z-[-1]"
          id="waving-video"
          autoPlay
          loop
          muted
          playsInline
          style={{
            backgroundImage:
              "url('https://assets-global.website-files.com/64383d8e5c3c91d9b995b8b7/64425178167de08b8f05b15a_banner%20video-poster-00001.jpg')",
          }}
        >
          <source
            src="https://assets-global.website-files.com/64383d8e5c3c91d9b995b8b7/64425178167de08b8f05b15a_banner%20video-transcode.mp4"
            type="video/mp4"
          />
          <source
            src="https://assets-global.website-files.com/64383d8e5c3c91d9b995b8b7/64425178167de08b8f05b15a_banner%20video-transcode.webm"
            type="video/webm"
          />
        </video>
        <div className="banner-video-overlay h-screen w-screen"></div>
      </section>
      {/* <section className="layer2 spacer bg-[#1f4681] min-h-screen"></section> */}
    </>
  );
}

export default Hero;
