import React from "react";
import { Link } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";

const MobileNavbar = ({ isOpen, toggleNavbar }) => {
  return (
    <div className={`md:hidden ${isOpen ? "block" : "hidden"}`}>
      <div className="flex flex-col items-left justify-center bg-gray-800 p-4 text-white">
        <div className="my-2">
          <ScrollLink to="services" smooth={true} duration={800} offset={-35}>
            Services
          </ScrollLink>
        </div>
        <div className="my-2">
          <ScrollLink to="talk" smooth={true} duration={800} offset={-35}>
            Culture
          </ScrollLink>
        </div>
        <div className="my-2">
          <ScrollLink to="pricing" smooth={true} duration={800} offset={-35}>
            Pricing
          </ScrollLink>
        </div>
        <div className="my-2">
          <ScrollLink to="contact" smooth={true} duration={800} offset={-35}>
            Connect
          </ScrollLink>
        </div>
        <div className="my-2">
          <ScrollLink to="about" smooth={true} duration={800} offset={-35}>
            About
          </ScrollLink>
        </div>
        <div className="my-2">
          <Link to="/Ar"> Ar</Link>
        </div>
      </div>
    </div>
  );
};

export default MobileNavbar;
