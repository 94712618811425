import React, { useEffect } from "react";

const GoogleAdsTracking = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://www.googletagmanager.com/gtag/js?id=AW-16452828385";
    script.async = true;
    document.head.appendChild(script);

    script.onload = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag("js", new Date());
      gtag("config", "AW-16452828385");
    };

    return () => {
      // Cleanup if component unmounts
      document.head.removeChild(script);
    };
  }, []);

  return null; // or render a placeholder if needed
};

export default GoogleAdsTracking;
