import whatsapp from "./whatsapp.png";
function Whatsapp() {
  const phoneNumber = "+968 91828471";
  return (
    <div className="fixed right-8 bottom-8 md:right-14 md:bottom-12">
      <a
        href={`https://wa.me/${phoneNumber}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={whatsapp} />
      </a>
    </div>
  );
}

export default Whatsapp;
