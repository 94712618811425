import Svg from "./svg";

function Talk() {
  return (
    <div className=" max-w-screen h-screen overflow-hidden flex items-center justify-center mt-8">
      <Svg />
      <div className="text-white text-2xl md:text-3xl text-center pb-32 px-2">
        We're easy to find <br />
        Don't be Shy!
      </div>
    </div>
  );
}

export default Talk;
