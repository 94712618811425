import React from "react";
import { Link } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";

const MobileNavbar_Ar = ({ isOpen, toggleNavbar }) => {
  return (
    <div className={`md:hidden ${isOpen ? "block" : "hidden"}`}>
      <div className="flex flex-col text-right justify-center bg-gray-800 p-4 text-white">
        <div className="my-2">
          <ScrollLink to="services" smooth={true} duration={800} offset={-35}>
            خدماتنا
          </ScrollLink>
        </div>
        <div className="my-2">
          <ScrollLink to="pricing" smooth={true} duration={800} offset={-35}>
            باقات
          </ScrollLink>
        </div>
        <div className="my-2">
          <ScrollLink to="talk" smooth={true} duration={800} offset={-35}>
            ثقافتنا
          </ScrollLink>
        </div>
        <div className="my-2">
          <ScrollLink to="contact" smooth={true} duration={800} offset={-35}>
            تواصل
          </ScrollLink>
        </div>
        <div className="my-2">
          <ScrollLink to="about" smooth={true} duration={800} offset={-35}>
            عن إمتياز
          </ScrollLink>
        </div>
        <div className="my-2">
          <Link to="/En"> En</Link>
        </div>
      </div>
    </div>
  );
};

export default MobileNavbar_Ar;
