import Tick_Svg from "./tick_svg";
const phoneNumber = "+968 91828471";

function Pricing() {
  return (
    <section id="pricing" class="py-10  sm:py-16 lg:py-24 text-white">
      <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div class="max-w-xl mx-auto text-center">
          <h2 class="text-4xl font-bold text-white lg:text-5xl sm:text-5xl">
            Marketing Pricing &amp; Plans
          </h2>
          <p class="mt-4 text-lg leading-relaxed text-white">
            plans suited for your business needs
          </p>
        </div>

        <div class="hidden mt-16 lg:block">
          <table class="w-full">
            <thead>
              <tr>
                <th class="py-8 pr-4"></th>

                <th class="px-4 py-8 text-center">
                  <span class="text-base font-medium text-blue-600">
                    {" "}
                    Business Basic{" "}
                  </span>
                  <p class="mt-6 text-6xl font-bold">168 OMR</p>
                  <p class="mt-2 text-base font-normal text-gray-500">
                    Per month
                  </p>
                </th>

                <th class="px-4 py-8 text-center">
                  <span class="text-base font-medium text-blue-600">
                    {" "}
                    Business Plus{" "}
                  </span>
                  <p class="mt-6 text-6xl font-bold">199 OMR</p>
                  <p class="mt-2 text-base font-normal text-gray-500">
                    Per month
                  </p>
                </th>

                <th class="px-4 py-8 text-center bg-[#339fbeff] rounded-t-xl">
                  <span class="px-4 py-2 text-base font-medium text-white bg-blue-600 rounded-full">
                    {" "}
                    Business Pro{" "}
                  </span>
                  <p class="mt-6 text-6xl font-bold text-white">249 OMR</p>
                  <p class="mt-2 text-base font-normal text-gray-200">
                    Per month
                  </p>
                </th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td class="py-4 pr-4 font-medium border-b border-gray-200">
                  # Of Platforms
                </td>

                <td class="px-4 py-4 text-center border-b border-gray-200">
                  2
                </td>

                <td class="px-4 py-4 text-center border-b border-gray-200">
                  3
                </td>

                <td class="px-4 py-4 text-center text-white bg-[#339fbeff] border-b border-white/20">
                  4
                </td>
              </tr>

              <tr>
                <td class="py-4 pr-4 font-medium border-b border-gray-200">
                  # Of Posts
                </td>

                <td class="px-4 py-4 text-center border-b border-gray-200">
                  5
                </td>

                <td class="px-4 py-4 text-center border-b border-gray-200">
                  8
                </td>

                <td class="px-4 py-4 text-center text-white bg-[#339fbeff] border-b border-white/20">
                  10
                </td>
              </tr>

              <tr>
                <td class="py-4 pr-4 font-medium border-b border-gray-200">
                  Creative Copywriting
                </td>

                <td class="px-4 py-4 text-center border-b border-gray-200">
                  <Tick_Svg />
                </td>

                <td class="px-4 py-4 text-center border-b border-gray-200">
                  <Tick_Svg />
                </td>

                <td class="px-4 py-4 text-center text-white bg-[#339fbeff] border-b border-white/20">
                  <Tick_Svg />
                </td>
              </tr>

              <tr>
                <td class="py-4 pr-4 font-medium border-b border-gray-200">
                  Market Research
                </td>

                <td class="px-4 py-4 text-center border-b border-gray-200">
                  <Tick_Svg />
                </td>

                <td class="px-4 py-4 text-center border-b border-gray-200">
                  <Tick_Svg />
                </td>

                <td class="px-4 py-4 text-center text-white bg-[#339fbeff] border-b border-white/20">
                  <Tick_Svg />
                </td>
              </tr>

              <tr>
                <td class="py-4 pr-4 font-medium border-b border-gray-200">
                  social media platforms desriptions
                </td>

                <td class="px-4 py-4 text-center border-b border-gray-200">
                  <Tick_Svg />
                </td>

                <td class="px-4 py-4 text-center border-b border-gray-200">
                  <Tick_Svg />
                </td>

                <td class="px-4 py-4 text-center text-white bg-[#339fbeff] border-b border-white/20">
                  <Tick_Svg />
                </td>
              </tr>

              <tr>
                <td class="py-4 pr-4 font-medium border-b border-gray-200">
                  mMnthly Reports
                </td>

                <td class="px-4 py-4 text-center border-b border-gray-200">
                  <Tick_Svg />
                </td>

                <td class="px-4 py-4 text-center border-b border-gray-200">
                  <Tick_Svg />
                </td>

                <td class="px-4 py-4 text-center text-white bg-[#339fbeff] border-b border-white/20">
                  <Tick_Svg />
                </td>
              </tr>

              <tr>
                <td class="py-4 pr-4 font-medium border-b border-gray-200">
                  Paid Ads
                </td>

                <td class="px-4 py-4 text-center border-b border-gray-200">
                  -
                </td>

                <td class="px-4 py-4 text-center border-b border-gray-200">
                  On One Platform
                </td>
                <td class="px-4 py-4 text-center text-white bg-[#339fbeff] border-b border-white/20">
                  On Two Platforms
                </td>
              </tr>

              <tr>
                <td class="py-4 pr-4 font-medium border-b border-gray-200">
                  Logo Design
                </td>

                <td class="px-4 py-4 text-center border-b border-gray-200">
                  -
                </td>

                <td class="px-4 py-4 text-center border-b border-gray-200">
                  -
                </td>

                <td class="px-4 py-4 text-center text-white bg-[#339fbeff] border-b border-white/20">
                  <Tick_Svg />
                </td>
              </tr>
              <tr>
                <td class="py-4 pr-4 font-medium border-b border-gray-200">
                  Website Design + 1 year free hosting
                </td>

                <td class="px-4 py-4 text-center border-b border-gray-200">
                  -
                </td>

                <td class="px-4 py-4 text-center border-b border-gray-200">
                  -
                </td>

                <td class="px-4 py-4 text-center text-white bg-[#339fbeff] border-b border-white/20">
                  <Tick_Svg />
                </td>
              </tr>

              <tr>
                <td class="py-6 pr-4"></td>

                <td class="px-4 py-6 text-center">
                  <a
                    href={`https://wa.me/${phoneNumber}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    class="inline-flex items-center font-semibold text-blue-600 hover:text-blue-700"
                  >
                    Get Started
                    <svg
                      class="w-4 h-4 ml-1"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </a>
                </td>

                <td class="px-4 py-6 text-center">
                  <a
                    href={`https://wa.me/${phoneNumber}`}
                    target="_blank"
                    class="inline-flex items-center font-semibold text-blue-600 hover:text-blue-700"
                  >
                    Get Started
                    <svg
                      class="w-4 h-4 ml-1"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </a>
                </td>

                <td class="px-4 py-6 text-center text-white bg-blue-600 rounded-b-xl">
                  <a
                    href={`https://wa.me/${phoneNumber}`}
                    target="_blank"
                    class="inline-flex items-center font-semibold text-white"
                  >
                    Get Started
                    <svg
                      class="w-4 h-4 ml-1"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="block mt-12 border-t border-b border-gray-200 divide-y divide-gray-200 lg:hidden">
        <div class="grid grid-cols-3 text-center divide-x divide-gray-200">
          <div class="px-2 py-2">
            <span class="text-sm font-medium text-blue-600">
              {" "}
              Business Basic{" "}
            </span>
            <p class="mt-2 text-xl font-bold">168 OMR</p>
            <span class="mt-1 text-sm font-normal text-gray-500">
              {" "}
              Per month{" "}
            </span>
          </div>

          <div class="px-2 py-2">
            <span class="text-sm font-medium text-blue-600">
              {" "}
              Business Plus{" "}
            </span>
            <p class="mt-2 text-xl font-bold">199 OMR</p>
            <span class="mt-1 text-sm font-normal text-gray-500">
              {" "}
              Per month{" "}
            </span>
          </div>

          <div class="px-2 py-2">
            <span class="text-sm font-medium text-blue-600">
              {" "}
              Business Pro{" "}
            </span>
            <p class="mt-2 text-xl font-bold">249 OMR</p>
            <span class="mt-1 text-sm font-normal text-gray-500">
              {" "}
              Per month{" "}
            </span>
          </div>
        </div>

        <div class="px-2 py-4 sm:px-4">
          <p class="font-semibold"># Of Platforms</p>
        </div>

        <div class="grid grid-cols-3 text-center divide-x divide-gray-200">
          <div class="px-2 py-2">2</div>

          <div class="px-2 py-2">3</div>

          <div class="px-2 py-2">4</div>
        </div>

        <div class="px-2 py-4 sm:px-4">
          <p class="font-semibold"># Of Posts</p>
        </div>

        <div class="grid grid-cols-3 text-center divide-x divide-gray-200">
          <div class="px-2 py-2">5</div>

          <div class="px-2 py-2">8</div>

          <div class="px-2 py-2">10</div>
        </div>

        <div class="px-2 py-4 sm:px-4">
          <p class="font-semibold">Creative Copywriting</p>
        </div>

        <div class="grid grid-cols-3 text-center divide-x divide-gray-200">
          <div class="px-2 py-2">
            <Tick_Svg />
          </div>

          <div class="px-2 py-2">
            <Tick_Svg />
          </div>

          <div class="px-2 py-2">
            <Tick_Svg />
          </div>
        </div>
        <div class="px-2 py-4 sm:px-4">
          <p class="font-semibold">social media platforms desriptions</p>
        </div>

        <div class="grid grid-cols-3 text-center divide-x divide-gray-200">
          <div class="px-2 py-2">
            <Tick_Svg />
          </div>

          <div class="px-2 py-2">
            <Tick_Svg />
          </div>

          <div class="px-2 py-2">
            <Tick_Svg />
          </div>
        </div>
        <div class="px-2 py-4 sm:px-4">
          <p class="font-semibold">Monthly Reports </p>
        </div>

        <div class="grid grid-cols-3 text-center divide-x divide-gray-200">
          <div class="px-2 py-2">
            <Tick_Svg />
          </div>

          <div class="px-2 py-2">
            <Tick_Svg />
          </div>

          <div class="px-2 py-2">
            <Tick_Svg />
          </div>
        </div>
        <div class="px-2 py-4 sm:px-4">
          <p class="font-semibold">Paid Ads</p>
        </div>

        <div class="grid grid-cols-3 text-center divide-x divide-gray-200">
          <div class="px-2 py-2">
            <Tick_Svg />
          </div>

          <div class="px-2 py-2">On One Platform </div>

          <div class="px-2 py-2">On Two Platforms </div>
        </div>
        <div class="px-2 py-4 sm:px-4">
          <p class="font-semibold">Logo Design</p>
        </div>

        <div class="grid grid-cols-3 text-center divide-x divide-gray-200">
          <div class="px-2 py-2">-</div>

          <div class="px-2 py-2">-</div>

          <div class="px-2 py-2">
            <Tick_Svg />
          </div>
        </div>
        <div class="px-2 py-4 sm:px-4">
          <p class="font-semibold">Website Design + 1 year free hosting</p>
        </div>

        <div class="grid grid-cols-3 text-center divide-x divide-gray-200">
          <div class="px-2 py-2">-</div>

          <div class="px-2 py-2">-</div>

          <div class="px-2 py-2">
            <Tick_Svg />
          </div>
        </div>

        <div class="grid grid-cols-3 text-center divide-x divide-gray-200">
          <div class="px-1 py-2 sm:px-4">
            <span class="text-sm font-medium text-blue-600">
              {" "}
              Business Basic{" "}
            </span>
            <p class="mt-2 text-xl font-bold">168 OMR</p>
            <span class="mt-1 text-sm font-normal text-gray-500">
              {" "}
              Per month{" "}
            </span>
            <a
              href={`https://wa.me/${phoneNumber}`}
              target="_blank"
              class="flex items-center justify-center w-full px-1 py-2 mt-5 text-sm text-white transition-all duration-200 bg-blue-600 border border-transparent rounded-md hover:bg-blue-700"
              role="button"
            >
              {" "}
              Get Started{" "}
            </a>
          </div>

          <div class="px-1 py-2 sm:px-4">
            <span class="text-sm font-medium text-blue-600">
              {" "}
              Business Plus{" "}
            </span>
            <p class="mt-2 text-xl font-bold">199 OMR</p>
            <span class="mt-1 text-sm font-normal text-gray-500">
              {" "}
              Per month{" "}
            </span>
            <a
              href={`https://wa.me/${phoneNumber}`}
              target="_blank"
              class="flex items-center justify-center w-full px-1 py-2 mt-5 text-sm text-white transition-all duration-200 bg-blue-600 border border-transparent rounded-md hover:bg-blue-700"
              role="button"
            >
              {" "}
              Get Started{" "}
            </a>
          </div>

          <div class="px-1 py-2 sm:px-4">
            <span class="text-sm font-medium text-blue-600">
              {" "}
              Business Pro{" "}
            </span>
            <p class="mt-2 text-xl font-bold">249 OMR</p>
            <span class="mt-1 text-sm font-normal text-gray-500">
              {" "}
              Per month{" "}
            </span>
            <a
              href={`https://wa.me/${phoneNumber}`}
              target="_blank"
              class="flex items-center justify-center w-full px-1 py-2 mt-5 text-sm text-white transition-all duration-200 bg-blue-600 border border-transparent rounded-md hover:bg-blue-700"
              role="button"
            >
              {" "}
              Get Started{" "}
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Pricing;
