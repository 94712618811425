import branding from "./branding_group1.png";

function Branding_Ar() {
  return (
    <div className="mx-auto w-[90%] md:w-[70%] max-h-screen mb-16 Ar">
      <div className="text-3xl text-white text-center font-bold mb-4 mt-12">
        علامة تجارية{" "}
        <span className="text-3xl text-center font-bold text-[#339fbeff] mb-4 mt-12">
          تنبض بالحياة
        </span>
      </div>
      <img
        src={branding}
        alt="Branding Image"
        className="w-full h-[65%] relative"
      />
    </div>
  );
}

export default Branding_Ar;
