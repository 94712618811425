import { Route, Routes } from "react-router-dom";
import "./App.css";
import Ar from "./Ar";
import En from "./En";
import { Analytics } from "@vercel/analytics/react";
import GoogleAdsTracking from "./GoogleAdsTracking";

function App() {
  return (
    <>
      <GoogleAdsTracking />
      <Routes>
        <Route path="/Ar" element={<Ar />} />
        <Route path="/En" element={<En />} />
        <Route path="/" element={<Ar />} />
      </Routes>
      <Analytics />
    </>
  );
}

export default App;
