import React from "react";
import logo2 from "./logo5.png";
import copywriting from "./copywriting.png";
import ads from "./ads.png";
import store from "./store.png";
import billboard from "./billboard.png";
import branding from "./branding.png";
import socialmediamarketing from "./social-media-marketing.png";

function Cards() {
  return (
    <div id="services" className="container mx-auto p-4 md:p-12 mt-[-20px]">
      <div className="col-span-2 md:w-9/12 mx-auto justify-center items-center text-center">
        <img
          className="w-[10rem] md:w-[18rem] mb-6 mx-auto"
          src={logo2}
          alt="Logo"
        />
        <div className="text-white text-3xl mb-6">
          Oman <span className="text-[#339fbeff] font-bold">#1</span> Marketing
          Agency
        </div>
        <div className="text-white text-xl mb-20 md:w-9/12 mx-auto">
          We at imteiaz embrace a unique approach. No upfront costs, just pure
          dedication to your success. Discover a world of results-driven digital
          marketing, crafted with passion and delivered with excellence. Your
          goals are our commitment.
        </div>
      </div>
      <div className="grid grid-cols-2 gap-4 text-white  mx-auto md:w-10/12">
        <div className="col-span-2  md:col-span-1 md:w-10/12   rounded-md mx-4">
          <img className="mx-auto" src={ads} />
          <h1 className="text-3xl   p-2 text-center">Paid Advertisements</h1>
          <div className="p-6">
            We run laser-focused Ads to ensure you get the best ROI (return on
            investment) possible. Depending on your target audience and the
            nature of your services, we will identify the most suitable ad type
            and the best media platforms to broadcast them on.
          </div>
        </div>
        <div className="col-span-2 md:col-span-1 md:w-10/12   rounded-md mx-4">
          <img className="mx-auto" src={socialmediamarketing} />
          <h1 className="text-3xl p-2 text-center">Social Media Marketing</h1>
          <div className="p-6">
            If you’re not on platforms like Facebook, Instagram, and LinkedIn,
            you’re missing out! Great marketing on social media can bring
            remarkable success to your business, creating devoted brand
            advocates and even driving leads and sales.
          </div>
        </div>
        <div className="col-span-2 md:col-span-1 md:w-10/12   rounded-md mx-4">
          <img className="mx-auto" src={copywriting} />
          <h1 className="text-3xl  p-2 text-center">Copywriting</h1>
          <div className="p-6">
            We write call-to-action, persuasive marketing and promotional
            materials that motivate people to take action, such as make a
            purchase, click on a link, donate to a cause, or schedule a
            consultation, both for Arabic and English audiences.
          </div>
        </div>
        <div className="col-span-2 md:col-span-1 md:w-10/12   rounded-md mx-4">
          <img className="mx-auto" src={billboard} />
          <h1 className="text-3xl p-2 text-center">Billboard Ads</h1>
          <div className="p-6">
            Picture this – your brand towering above the rest, commanding
            attention from every passerby, leaving an indelible mark on the
            minds of your target audience. Powerful storytellers, narrating your
            brand's narrative against the vast canvas of the urban jungle.
          </div>
        </div>
        <div className="col-span-2 md:col-span-1 md:w-10/12   rounded-md mx-4">
          <img className="mx-auto" src={store} />
          <h1 className="text-3xl  p-2 text-center">E-Stores</h1>
          <div className="p-6">
            Selling products and services over the internet has become a staple
            of modern commerce. That shift is here to stay, presenting
            opportunities for business owners to thrive online. We help you set
            up your internet shop and understand how it all works.
          </div>
        </div>
        <div className="col-span-2 md:col-span-1 md:w-10/12   rounded-md mx-4">
          <img className="mx-auto" src={branding} />
          <h1 className="text-3xl  p-2 text-center">Branding</h1>
          <div className="p-6">
            Branding is the creative, strategic process of telling potential
            customers what your company is all about: who you are, what you care
            about, why they should work with you and what they can expect from
            you.
          </div>
        </div>
      </div>
    </div>
  );
}

export default Cards;
