import React from "react";
import logo2 from "./logo5.png";
import copywriting from "./copywriting.png";
import ads from "./ads.png";
import store from "./store.png";
import billboard from "./billboard.png";
import branding from "./branding.png";
import socialmediamarketing from "./social-media-marketing.png";

function Cards_Ar() {
  return (
    <div id="services" className="Ar container mx-auto p-4 md:p-12 mt-[-20px]">
      <br />
      <div className="col-span-2 md:w-9/12 mx-auto justify-center items-center text-center ">
        <img
          className="w-[10rem] md:w-[18rem] mb-6 mx-auto"
          src={logo2}
          alt="Logo"
        />
        <div className="text-white  text-3xl mb-6">
          التسويق الالكتروني
          <span className="text-[#339fbeff] font-bold"> #1 </span> في عمان
        </div>
        <div className="text-white text-xl mb-20 md:w-10/12 mx-auto">
          نحن في إمتياز نتبنى نهجًا فريدًا. بدون تكلفة مقدمة، تفانٍ في نجاحك.
          اكتشف عالم التسويق الرقمي الذي يعتمد على النتائج، صُنع بشغف .وتقديم
          بتميز. أهدافك، هي التزامنا
        </div>
      </div>
      <br />
      <br />
      <div className="grid grid-cols-2 gap-6 text-white mx-auto md:w-10/12">
        <div className="col-span-2  md:col-span-1 md:w-10/12   rounded-md mx-4">
          <img className="mx-auto" src={ads} />
          <h1 className="text-3xl   p-2 text-center">الاعلانات المدفوعة </h1>
          <div className="p-6">
            ندير إعلانات موجهة بشكل دقيق باستخدام احدث التقنيات لضمان حصولك على
            أفضل عائد على الاستثمار علي افضل النتائج. اعتمادًا على جمهورك
            المستهدف وطبيعة خدماتك، سنحدد نوع الإعلان الأكثر ملاءمة وأفضل
            الوسائط .لبثها عليها
          </div>
        </div>
        <div className="col-span-2 md:col-span-1 md:w-10/12   rounded-md mx-4">
          <img className="mx-auto" src={socialmediamarketing} />
          <h1 className="text-3xl p-2 text-center">
            {" "}
            تسويق وسائل التواصل الاجتماعي
          </h1>
          <div className="p-6">
            إذا لم تكن على منصات مثل فيسبوك وإنستجرام ولينكد إن، فأنت تفوت
            الكثير! يمكن أن يحقق التسويق الرائع على وسائل التواصل الاجتماعي
            نجاحًا ملحوظًا لعملك، حيث يمكن أن يخلق مؤيدين مخلصين للعلامة
            .التجارية و يحقق زيادة في عدد العملاء والمبيعات
          </div>
        </div>
        <div className="col-span-2 md:col-span-1 md:w-10/12   rounded-md mx-4">
          <img className="mx-auto" src={copywriting} />
          <h1 className="text-3xl  p-2 text-center"> تحرير محتوي اعلاني</h1>
          <div className="p-6">
            نقوم بكتابة نصوص تسويقية قوية تحفز الأفراد على اتخاذ إجراء، مثل
            عملية شراء، أو النقر علي رابط، أو التبرع , أو تحديد موعد .لاستشارة،
            سواء للجمهور العربي أو الإنجليزي
          </div>
        </div>
        <div className="col-span-2 md:col-span-1 md:w-10/12   rounded-md mx-4">
          <img className="mx-auto" src={billboard} />
          <h1 className="text-3xl p-2 text-center">لوحات اعلانية</h1>
          <div className="p-6">
            نقوم بكتابة نصوص تسويقية قوية تحفز الأفراد على اتخاذ إجراء، مثل
            عملية شراء، أو النقر علي رابط، أو التبرع , أو تحديد موعد .لاستشارة،
            سواء للجمهور العربي أو الإنجليزي
          </div>
        </div>
        <div className="col-span-2 md:col-span-1 md:w-10/12   rounded-md mx-4">
          <img className="mx-auto" src={store} />
          <h1 className="text-3xl  p-2 text-center"> المتاجر الإلكترونية</h1>
          <div className="p-6">
            أصبح بيع المنتجات والخدمات عبر الإنترنت جزءًا أساسيًا من التجارة
            الحديثة. هذا التحول هو هنا ليبقى، مما يقدم فرصًا لأصحاب الأعمال
            للازدهار عبر الإنترنت. نحن نساعدك في إعداد متجرك على الإنترنت وفهم
            .كيفية عمله بشكل كامل
          </div>
        </div>
        <div className="col-span-2 md:col-span-1 md:w-10/12   rounded-md mx-4">
          <img className="mx-auto" src={branding} />
          <h1 className="text-3xl  p-2 text-center">بناء العلامة التجارية </h1>
          <div className="p-6">
            العلامة التجارية هي العملية الإبداعية والاستراتيجية لإخبار الإمكانات
            العملاء حول ما تدور حوله شركتك: من أنت، وما الذي يهمك حول سبب وجوب
            العمل معك وما الذي يمكنهم توقعه منك
          </div>
        </div>
      </div>
    </div>
  );
}

export default Cards_Ar;
