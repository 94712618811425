import { useEffect, useState } from "react";
import "./App.css";
import Footer from "./footer";
import Hero from "./hero";
import Services from "./services";
import Talk from "./talk";
import Whatsapp from "./whatsapp";
import Loader from "./loader";
import Branding from "./branding";
import Pricing from "./pricing";

function En() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fakeDataFetch = () => {
      setTimeout(() => {
        setIsLoading(false);
      }, 6000);
    };

    fakeDataFetch();
  }, []);

  return isLoading ? (
    <div className="flex items-center justify-center h-screen bg-gray-800">
      <h1 className="text-white text-center">
        <Loader />
      </h1>
    </div>
  ) : (
    <>
      <Whatsapp />
      <Hero />
      <Services />
      <Branding />
      <Pricing />
      <Talk />
      <Footer />
    </>
  );
}

export default En;
