import "./App.css";
import Footer_Ar from "./footer_Ar";
import Hero_Ar from "./hero-Ar";
import Cards_Ar from "./services_Ar";
import Talk_Ar from "./talk_Ar";
import Whatsapp from "./whatsapp";
import Loader from "./loader";

import { useEffect, useState } from "react";
import Branding_Ar from "./Branding_ar";
import Pricing_Ar from "./pricing_Ar";

function Ar() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fakeDataFetch = () => {
      setTimeout(() => {
        setIsLoading(false);
      }, 6000);
    };

    fakeDataFetch();
  }, []);

  return isLoading ? (
    <div className="flex items-center justify-center h-screen bg-gray-800">
      <h1 className="text-white text-center">
        <Loader />
      </h1>
    </div>
  ) : (
    <>
      <Whatsapp />
      <Hero_Ar />
      <Cards_Ar />
      <Branding_Ar />
      <Pricing_Ar />
      <Talk_Ar />
      <Footer_Ar />
    </>
  );
}

export default Ar;
