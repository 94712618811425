import branding from "./branding_group1.png";

function Branding() {
  return (
    <div className="mx-auto w-[90%] md:w-[70%] max-h-screen mb-16">
      <div className="text-3xl text-white text-center font-bold mb-4 mt-12">
        360° of{" "}
        <span className="text-3xl text-center font-bold text-[#339fbeff] mb-4 mt-12">
          Branding
        </span>
      </div>
      <img
        src={branding}
        alt="Branding Image"
        className="w-full h-[65%] relative"
      />
    </div>
  );
}

export default Branding;
