import Tick_Svg from "./tick_svg";
const phoneNumber = "+968 91828471";

function Pricing_Ar() {
  return (
    <section class="py-10  sm:py-16 lg:py-24 text-white Ar">
      <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8 rtl">
        <div class="max-w-xl mx-auto text-center">
          <h2 class="text-4xl font-bold text-white lg:text-5xl sm:text-5xl">
            باقات التسويق
          </h2>
          <p class="mt-4 text-lg leading-relaxed text-white">
            باقات مميزة مناسبة لكل احتياجاتك
          </p>
        </div>

        <div class="hidden mt-16 lg:block">
          <table class="w-full">
            <thead>
              <tr>
                <th class="py-8 pr-4"></th>

                <th class="px-4 py-8 text-center">
                  <span class="text-base font-medium text-blue-600">
                    {" "}
                    بزنس بيزك{" "}
                  </span>
                  <p class="mt-6 text-6xl font-bold">168 ريال</p>
                  <p class="mt-2 text-base font-normal text-gray-500">
                    في الشهر
                  </p>
                </th>

                <th class="px-4 py-8 text-center">
                  <span class="text-base font-medium text-blue-600">
                    {" "}
                    بيزنس بلس{" "}
                  </span>
                  <p class="mt-6 text-6xl font-bold">199 ريال</p>
                  <p class="mt-2 text-base font-normal text-gray-500">
                    في الشهر
                  </p>
                </th>

                <th class="px-4 py-8 text-center bg-[#339fbeff] rounded-t-xl">
                  <span class="px-4 py-2 text-base font-medium text-white bg-blue-600 rounded-full">
                    {" "}
                    بزنس برو{" "}
                  </span>
                  <p class="mt-6 text-6xl font-bold text-white">249 ريال</p>
                  <p class="mt-2 text-base font-normal text-gray-200">
                    في الشهر
                  </p>
                </th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td class="py-4 pr-4 font-medium border-b border-gray-200">
                  عدد المنصات
                </td>

                <td class="px-4 py-4 text-center border-b border-gray-200">
                  2
                </td>

                <td class="px-4 py-4 text-center border-b border-gray-200">
                  3
                </td>

                <td class="px-4 py-4 text-center text-white bg-[#339fbeff] border-b border-white/20">
                  4
                </td>
              </tr>

              <tr>
                <td class="py-4 pr-4 font-medium border-b border-gray-200">
                  عدد المنشورات
                </td>

                <td class="px-4 py-4 text-center border-b border-gray-200">
                  5
                </td>

                <td class="px-4 py-4 text-center border-b border-gray-200">
                  8
                </td>

                <td class="px-4 py-4 text-center text-white bg-[#339fbeff] border-b border-white/20">
                  10
                </td>
              </tr>

              <tr>
                <td class="py-4 pr-4 font-medium border-b border-gray-200">
                  كتابة المحتوى الإعلاني والإبداعي
                </td>

                <td class="px-4 py-4 text-center border-b border-gray-200 ">
                  <Tick_Svg />
                </td>

                <td class="px-4 py-4 text-center border-b border-gray-200 ">
                  <Tick_Svg />
                </td>

                <td class="px-4 py-4 text-center border-b border-gray-200  bg-[#339fbeff]">
                  <Tick_Svg />
                </td>
              </tr>
              <tr>
                <td class="py-4 pr-4 font-medium border-b border-gray-200">
                  دراسة المنافسين
                </td>

                <td class="px-4 py-4 text-center border-b border-gray-200 ">
                  <Tick_Svg />
                </td>

                <td class="px-4 py-4 text-center border-b border-gray-200 ">
                  <Tick_Svg />
                </td>

                <td class="px-4 py-4 text-center border-b border-gray-200  bg-[#339fbeff]">
                  <Tick_Svg />
                </td>
              </tr>
              <tr>
                <td class="py-4 pr-4 font-medium border-b border-gray-200">
                  وضع وصف دقيق على قنوات السوشيال ميديا{" "}
                </td>

                <td class="px-4 py-4 text-center border-b border-gray-200 ">
                  <Tick_Svg />
                </td>

                <td class="px-4 py-4 text-center border-b border-gray-200 ">
                  <Tick_Svg />
                </td>

                <td class="px-4 py-4 text-center border-b border-gray-200  bg-[#339fbeff]">
                  <Tick_Svg />
                </td>
              </tr>
              <tr>
                <td class="py-4 pr-4 font-medium border-b border-gray-200">
                  تقرير في نهاية كل شهر{" "}
                </td>

                <td class="px-4 py-4 text-center border-b border-gray-200 ">
                  <Tick_Svg />
                </td>

                <td class="px-4 py-4 text-center border-b border-gray-200 ">
                  <Tick_Svg />
                </td>

                <td class="px-4 py-4 text-center border-b border-gray-200  bg-[#339fbeff]">
                  <Tick_Svg />
                </td>
              </tr>
              <tr>
                <td class="py-4 pr-4 font-medium border-b border-gray-200">
                  انشاء موقع الشركة على خرائط جوجل وإدارته{" "}
                </td>

                <td class="px-4 py-4 text-center border-b border-gray-200 ">
                  -
                </td>

                <td class="px-4 py-4 text-center border-b border-gray-200 ">
                  <Tick_Svg />
                </td>

                <td class="px-4 py-4 text-center border-b border-gray-200  bg-[#339fbeff]">
                  <Tick_Svg />
                </td>
              </tr>

              <tr>
                <td class="py-4 pr-4 font-medium border-b border-gray-200">
                  اعلان ترويجي مدفوع
                </td>

                <td class="px-4 py-4 text-center border-b border-gray-200 ">
                  -
                </td>

                <td class="px-4 py-4 text-center border-b border-gray-200 ">
                  علي منصة واحدة
                </td>

                <td class="px-4 py-4 text-center border-b border-gray-200  bg-[#339fbeff]">
                  علي منصتين
                </td>
              </tr>

              <tr>
                <td class="py-4 pr-4 font-medium border-b border-gray-200">
                  تصميم لوغو و شعار{" "}
                </td>

                <td class="px-4 py-4 text-center border-b border-gray-200 ">
                  -
                </td>

                <td class="px-4 py-4 text-center border-b border-gray-200 ">
                  -
                </td>

                <td class="px-4 py-4 text-center border-b border-gray-200  bg-[#339fbeff]">
                  <Tick_Svg />
                </td>
              </tr>
              <tr>
                <td class="py-4 pr-4 font-medium border-b border-gray-200">
                  تصميم موقع اللكتروني + استضافة و اسم نطاق مجاني لمدة سنة مجاني{" "}
                </td>

                <td class="px-4 py-4 text-center border-b border-gray-200 ">
                  -
                </td>

                <td class="px-4 py-4 text-center border-b border-gray-200 ">
                  -
                </td>

                <td class="px-4 py-4 text-center border-b border-gray-200  bg-[#339fbeff]">
                  <Tick_Svg />
                </td>
              </tr>
              <tr>
                <td class="py-6 pr-4"></td>

                <td class="px-4 py-6 text-center">
                  <a
                    href={`https://wa.me/${phoneNumber}`}
                    target="_blank"
                    class="inline-flex items-center font-semibold text-blue-600 hover:text-blue-700"
                  >
                    احصل عليها
                    <svg
                      className="w-4 h-4 ml-1 ltr"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L4.414 9H17a1 1 0 110 2H4.414l5.293 5.293a1 1 0 010 1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </a>
                </td>

                <td class="px-4 py-6 text-center">
                  <a
                    href={`https://wa.me/${phoneNumber}`}
                    target="_blank"
                    class="inline-flex items-center font-semibold text-blue-600 hover:text-blue-700"
                  >
                    احصل عليها
                    <svg
                      className="w-4 h-4 ml-1 ltr"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L4.414 9H17a1 1 0 110 2H4.414l5.293 5.293a1 1 0 010 1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </a>
                </td>

                <td class="px-4 py-6 text-center text-white bg-blue-600 rounded-b-xl">
                  <a
                    href={`https://wa.me/${phoneNumber}`}
                    target="_blank"
                    class="inline-flex items-center font-semibold text-white"
                  >
                    احصل عليها
                    <svg
                      className="w-4 h-4 ml-1 ltr"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L4.414 9H17a1 1 0 110 2H4.414l5.293 5.293a1 1 0 010 1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      {/* mobile */}
      <div class="block mt-12 border-t border-b border-gray-200 divide-y divide-gray-200 lg:hidden">
        <div class="grid grid-cols-3 text-center divide-x divide-gray-200">
          <div class="px-2 py-2">
            <span class="text-sm font-medium text-blue-600"> بزنس بيزك </span>
            <p class="mt-2 text-xl font-bold">168 ريال</p>
            <span class="mt-1 text-sm font-normal text-gray-500">
              {" "}
              في الشهر{" "}
            </span>
          </div>

          <div class="px-2 py-2">
            <span class="text-sm font-medium text-blue-600"> بيزك بلس </span>
            <p class="mt-2 text-xl font-bold">199</p>
            <span class="mt-1 text-sm font-normal text-gray-500">
              {" "}
              في الشهر{" "}
            </span>
          </div>

          <div class="px-2 py-2">
            <span class="text-sm font-medium text-blue-600"> بيزنس برو </span>
            <p class="mt-2 text-xl font-bold">249 ريال</p>
            <span class="mt-1 text-sm font-normal text-gray-500">
              {" "}
              في الشهر{" "}
            </span>
          </div>
        </div>

        <div class="px-2 py-4 sm:px-4 text-right">
          <p class="font-semibold">عدد المنصات</p>
        </div>

        <div class="grid grid-cols-3 text-center divide-x divide-gray-200">
          <div class="px-2 py-2">2</div>

          <div class="px-2 py-2">3</div>

          <div class="px-2 py-2">4</div>
        </div>

        <div class="px-2 py-4 sm:px-4 text-right">
          <p class="font-semibold">عدد المنشورات</p>
        </div>

        <div class="grid grid-cols-3 text-center divide-x divide-gray-200">
          <div class="px-2 py-2">5</div>

          <div class="px-2 py-2">8</div>

          <div class="px-2 py-2">10</div>
        </div>

        <div class="px-2 py-4 sm:px-4 text-right">
          <p class="font-semibold">كتابة المحتوى الإعلاني والإبداعي</p>
        </div>

        <div class="grid grid-cols-3 text-center divide-x divide-gray-200">
          <div class="px-2 py-2">
            <Tick_Svg />
          </div>

          <div class="px-2 py-2">
            <Tick_Svg />
          </div>

          <div class="px-2 py-2">
            <Tick_Svg />
          </div>
        </div>

        <div class="px-2 py-4 sm:px-4 text-right">
          <p class="font-semibold">دراسة المنافسين</p>
        </div>

        <div class="grid grid-cols-3 text-center divide-x divide-gray-200">
          <div class="px-2 py-2">
            {" "}
            <Tick_Svg />
          </div>

          <div class="px-2 py-2">
            <Tick_Svg />
          </div>

          <div class="px-2 py-2">
            <Tick_Svg />
          </div>
        </div>

        <div class="px-2 py-4 sm:px-4 text-right">
          <p class="font-semibold">وضع وصف دقيق على قنوات السوشيال ميديا </p>
        </div>

        <div class="grid grid-cols-3 text-center divide-x divide-gray-200">
          <div class="px-2 py-2">
            {" "}
            <Tick_Svg />
          </div>

          <div class="px-2 py-2">
            <Tick_Svg />
          </div>

          <div class="px-2 py-2">
            <Tick_Svg />
          </div>
        </div>

        <div class="px-2 py-4 sm:px-4 text-right">
          <p class="font-semibold">تقرير في نهاية كل شهر </p>
        </div>

        <div class="grid grid-cols-3 text-center divide-x divide-gray-200">
          <div class="px-2 py-2">
            {" "}
            <Tick_Svg />
          </div>

          <div class="px-2 py-2">
            <Tick_Svg />
          </div>

          <div class="px-2 py-2">
            <Tick_Svg />
          </div>
        </div>

        <div class="px-2 py-4 sm:px-4 text-right">
          <p class="font-semibold">انشاء موقع الشركة على خرائط جوجل وإدارته </p>
        </div>

        <div class="grid grid-cols-3 text-center divide-x divide-gray-200">
          <div class="px-2 py-2">-</div>

          <div class="px-2 py-2">
            <Tick_Svg />
          </div>

          <div class="px-2 py-2">
            <Tick_Svg />
          </div>
        </div>
        <div class="px-2 py-4 sm:px-4 text-right">
          <p class="font-semibold">اعلان ترويجي مدفوع</p>
        </div>

        <div class="grid grid-cols-3 text-center divide-x divide-gray-200">
          <div class="px-2 py-2">-</div>

          <div class="px-2 py-2">علي منصة واحدة</div>

          <div class="px-2 py-2">علي منصتين</div>
        </div>
        <div class="px-2 py-4 sm:px-4 text-right">
          <p class="font-semibold"> تصميم لوغو و شعار </p>
        </div>

        <div class="grid grid-cols-3 text-center divide-x divide-gray-200">
          <div class="px-2 py-2">-</div>

          <div class="px-2 py-2">-</div>

          <div class="px-2 py-2">
            <Tick_Svg />
          </div>
        </div>
        <div class="px-2 py-4 sm:px-4 text-right">
          <p class="font-semibold">
            تصميم موقع اللكتروني + استضافة و اسم نطاق مجاني لمدة سنة مجاني{" "}
          </p>
        </div>

        <div class="grid grid-cols-3 text-center divide-x divide-gray-200">
          <div class="px-2 py-2">-</div>

          <div class="px-2 py-2">-</div>

          <div class="px-2 py-2">
            <Tick_Svg />
          </div>
        </div>

        <div class="grid grid-cols-3 text-center divide-x divide-gray-200">
          <div class="px-1 py-2 sm:px-4">
            <span class="text-sm font-medium text-blue-600"> بيزنس بيزك </span>
            <p class="mt-2 text-xl font-bold">168 ريال</p>
            <span class="mt-1 text-sm font-normal text-gray-500">
              {" "}
              في الشهر{" "}
            </span>
            <a
              href={`https://wa.me/${phoneNumber}`}
              target="_blank"
              class="flex items-center justify-center w-full px-1 py-2 mt-5 text-sm text-white transition-all duration-200 bg-[#339fbeff] border border-transparent rounded-md hover:bg-blue-700"
              role="button"
            >
              {" "}
              احصل عليها{" "}
            </a>
          </div>

          <div class="px-1 py-2 sm:px-4">
            <span class="text-sm font-medium text-blue-600"> بيزنس برو </span>
            <p class="mt-2 text-xl font-bold">199 ريال</p>
            <span class="mt-1 text-sm font-normal text-gray-500">
              {" "}
              في الشهر{" "}
            </span>
            <a
              href={`https://wa.me/${phoneNumber}`}
              target="_blank"
              class="flex items-center justify-center w-full px-1 py-2 mt-5 text-sm text-white transition-all duration-200 bg-[#339fbeff] border border-transparent rounded-md hover:bg-blue-700"
              role="button"
            >
              {" "}
              احصل عليها{" "}
            </a>
          </div>

          <div class="px-1 py-2 sm:px-4">
            <span class="text-sm font-medium text-blue-600"> بيزنس برو </span>
            <p class="mt-2 text-xl font-bold">249 ريال</p>
            <span class="mt-1 text-sm font-normal text-gray-500">
              {" "}
              في الشهر{" "}
            </span>
            <a
              href={`https://wa.me/${phoneNumber}`}
              target="_blank"
              class="flex items-center justify-center w-full px-1 py-2 mt-5 text-sm text-white transition-all duration-200 bg-[#339fbeff] border border-transparent rounded-md hover:bg-blue-700"
              role="button"
            >
              {" "}
              احصل عليها{" "}
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Pricing_Ar;
